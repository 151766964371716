import React, {useState} from 'react'
import {Link, withPrefix} from 'gatsby'

const Navbar = ({page}) => {
    const [isActive, setActive] = useState(false);
    const [navbarActiveClass, setNavbarActiveClass] = useState('');

    const toggleHamburger = () => {
        const state = !isActive;
        setActive(state);
        setNavbarActiveClass(state ? 'is-active' : '');
    }

    return (
        <nav className="navbar" role="navigation" area-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item osi-horizontal-logo" to="/">
                    <img src={`${withPrefix('/')}img/osi-logo-h.png`} alt="OPENSPHERE Inc." />
                </Link>

                { /* eslint-disable-next-line */ }
                <a role="button" className={`navbar-burger burger ${navbarActiveClass}`} aria-label="menu" aria-expanded="false"
                   data-target="osi-navbar" onClick={() => toggleHamburger()}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="osi-navbar" className={`navbar-menu ${navbarActiveClass}`}>
                <div className="navbar-start">
                    <Link to="/" className={`navbar-item ${page === 'index' && 'is-active'}`} onClick={() => toggleHamburger()}>
                        Home
                    </Link>
                    <Link to="/about/" className={`navbar-item ${page === 'about' && 'is-active'}`} onClick={() => toggleHamburger()}>
                        About
                    </Link>
                    <Link to="/works/" className={`navbar-item ${page === 'works' && 'is-active'}`} onClick={() => toggleHamburger()}>
                        Works
                    </Link>
                    <Link to="/opensource/" className={`navbar-item ${page === 'opensource' && 'is-active'}`} onClick={() => toggleHamburger()}>
                        OSS
                    </Link>
                    <Link to="/contact/" className={`navbar-item ${page === 'contact' && 'is-active'}`} onClick={() => toggleHamburger()}>
                        Contact
                    </Link>
                    <a href="https://blog.opensphere.co.jp/" className="navbar-item" onClick={() => toggleHamburger()}>
                        Blog
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
