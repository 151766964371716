import React from 'react'
import {Link} from "gatsby";

const Header = ({logoImg, className, logoImgAlt}) => {
    return (
        <div className={`osi-header ${className}`}>
            <div className="osi-header-body">
                <div className="osi-logo">
                    <Link to="/" title="Logo">
                        <img src={logoImg} alt={logoImgAlt} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Header
