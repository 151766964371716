import React from 'react'
import {Helmet} from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'
import Header from './Header'
import './all.scss'
import useSiteMetadata from './SiteMetadata'
import {withPrefix} from 'gatsby'

const TemplateWrapper = ({children, page}) => {
    const {title, description} = useSiteMetadata()
    return (
        <div className="osi-frame">
            <Helmet>
                <html lang="en"/>
                <title>{title}</title>
                <script src="https://kit.fontawesome.com/7de1c6dfdb.js" crossOrigin="anonymous"></script>
                <meta name="description" content={description}/>
                <link rel="preload" href="/img/osi-logo-h.png" as="image"/>
                <link rel="preload" href="/img/logo_os.gif" as="image"/>

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix('/')}img/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/icon-192x192.png`}
                    sizes="192x192"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/icon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/icon-16x16.png`}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
                    color="#899322"
                />
                <meta name="theme-color" content="#fff"/>

                <meta property="og:type" content="business.business"/>
                <meta property="og:title" content={title}/>
                <meta property="og:url" content="/"/>
                <meta
                    property="og:image"
                    content={`${withPrefix('/')}img/og-image.jpg`}
                />
            </Helmet>
            <Header className="osi-vertical-header" logoImg={`${withPrefix('/')}img/logo_os.gif`} logoImgAlt="OPENSPHERE Inc." />
            <div className="osi-body">
                <Navbar page={page} />
                <div>{children}</div>
                <Footer/>
            </div>
        </div>
    )
}

export default TemplateWrapper
