import React from 'react'

const Footer = () => {
    return (
        <footer className="footer has-text-right">
            <div className="content">
                <p>Copyright &copy; {(new Date()).getFullYear()} OPENSPHERE Inc.</p>
            </div>
        </footer>
    )
}

export default Footer
